<template>
  <span :class="`spilt ${className}`">
    <div class="displayLine">
      <router-link
        :to="{
          name: 'DownlineRepDetail',
          params: {
            downlineRepId: encodedId(rep.agent_id),
          },
        }"
        >{{
          `${rep.agent_name} - Level
                        ${rep.agent_level}
                        (${rep.agent_code})`
        }}</router-link
      >

      <template v-if="rep.downline && rep.downline.length > 0">
        <downline-rep-toggle :uplineId="rep.agent_id" />
      </template>

      <br /><small class="email-text">{{ rep.agent_email }}</small>
    </div>
  </span>
</template>
<script>
import DownlineRepToggle from "./DownlineRepToggle.vue";

export default {
  components: { DownlineRepToggle },
  name: "DownlineTreeCard",
  props: {
    rep: {
      required: true,
      type: Object,
      default: null,
    },
    className: {
      required: false,
      type: String,
      default: "",
    },
  },
  data: () => ({
    selectedReps: [],
  }),
  methods: {
    encodedId(id) {
      return btoa(id);
    },
  },
};
</script>
