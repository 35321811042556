<template>
  <div class="page-wrapper viewQuotes-wrap">
    <div class="container-fluid">
      <breadcrumb :items="breadcrumbItems" />
      <div class="page-title">
        <h1>Downline Tree</h1>
      </div>
      <div class="row downlineDisplay-card">
        <div class="col-12">
          <div id="tree" v-if="Object.keys(downlineList).length">
            <div class="branch">
              <div class="entry">
                <downline-tree-card
                  :rep="downlineList"
                />
                <template v-if="downlineList.downline">
                  <downline-tree-node
                    :downline="downlineList.downline"
                    :upline="downlineList"
                    firstSubNode
                  />
                </template>
              </div>
            </div>
          </div>
          <p v-else class="text-center">No Record Found</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import DownlineTreeCard from "./DownlineTreeCard.vue";
import DownlineTreeNode from "./DownlineTreeNode.vue";
export default {
  components: { DownlineTreeCard, DownlineTreeNode },
  name: "DownlineTree",
  data: () => ({
    downlineList: {},
    breadcrumbItems: [{ title: "Downline Tree", active: true }],
    allSelectedReps: [],
  }),
  methods: {
    async getDownlineList(agentId = null) {
      let url = `v2/get-rep-tree?agent_id=${agentId}`;
      let loader = this.$loading.show();
      await Api.get(url)
        .then((res) => {
          this.downlineList = res.data.data;
        })
        .catch(() => (this.downlineList = {}))
        .finally(() => {
          loader.hide();
        });
    },
    encodedId(id) {
      return btoa(id);
    },
  },
  created() {
    let agentId = this.$route.params.agent_id
      ? atob(this.$route.params.agent_id)
      : this.$store.getters.repId;
    this.getDownlineList(agentId);
  },
};
</script>

<style scoped>
.d-none {
  display: none;
}
.toggle {
  font-weight: bolder;
  font-size: 2rem;
}
</style>
<style src="@/assets/css/downline-tree.css"></style>
