var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    class: "spilt ".concat(_vm.className)
  }, [_c('div', {
    staticClass: "displayLine"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'DownlineRepDetail',
        params: {
          downlineRepId: _vm.encodedId(_vm.rep.agent_id)
        }
      }
    }
  }, [_vm._v(_vm._s("".concat(_vm.rep.agent_name, " - Level ").concat(_vm.rep.agent_level, " (").concat(_vm.rep.agent_code, ")")))]), _vm.rep.downline && _vm.rep.downline.length > 0 ? [_c('downline-rep-toggle', {
    attrs: {
      "uplineId": _vm.rep.agent_id
    }
  })] : _vm._e(), _c('br'), _c('small', {
    staticClass: "email-text"
  }, [_vm._v(_vm._s(_vm.rep.agent_email))])], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }