<template>
  <div :id="`node-inner-${upline.agent_id}`" class="d-none">
    <div v-for="downlineList in downline" :key="downlineList.agent_id">
      <div class="branch">
        <div
          class="line-link"
          :style="firstSubNode ? 'margin-left: 0.1rem' : ''"
        >
          <div :class="firstSubNode ? 'entry sub' : 'entry'">
            <img
              src="@/assets/images/right-arrow-icon.svg"
              class="right-arrow-icon"
            />
            <downline-tree-card
              :rep="downlineList"
              className="spilt-down"
            />
            <template v-if="downlineList.downline">
              <downline-tree-node
                :downline="downlineList.downline"
                :upline="downlineList"
              />
            </template>
            <!-- recursive call to downline_node_1.downline -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DownlineTreeCard from "./DownlineTreeCard.vue";
export default {
  name: "DownlineTreeNode",
  components: { DownlineTreeCard },
  props: {
    downline: {
      required: true,
      type: Array,
      default: null,
    },
    upline: {
      required: true,
      type: Object,
    },
    firstSubNode: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
};
</script>
